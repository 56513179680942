import useManifoldCSS from '@manifoldxyz/css-reset';
import { MANIFOLD_LOAD_REQUEST, MManifoldRefreshWidgets } from '@/constants/events';
import MWalletIdentity from '@/exports/MWalletIdentity.vue';
import MWalletIdentityNoTimeout from '@/exports/MWalletIdentityNoTimeout.vue';
import { name as packageName } from '../package.json';
import { renderComponentWithApp } from './mount';

// For usage outside of a Vue application but still in JS:
let mInterval!: number | boolean;
function handleLoad (event: Event | MManifoldRefreshWidgets) {
  let timeout = 10;
  if (event.type === MANIFOLD_LOAD_REQUEST) {
    const detail = (<MManifoldRefreshWidgets>event).detail;
    if (detail && detail.widget && detail.widget !== 'm-wallet-identity') {
      // not a creator identity refresh request
      return;
    }
    // clear current load request if it exists and reload
    if (mInterval) {
      clearInterval(mInterval as number);
      mInterval = false;
    }
  }
  if (!mInterval) {
    mInterval = window.setInterval(() => {
      timeout--;

      // Grab all empty widget containers from the DOM
      const elements = document.querySelectorAll("[data-widget='m-wallet-identity']");

      if (timeout === 0) {
        clearInterval(mInterval as number);
        console.error('MWalletIdentity failed to mount due to time out.');
        return;
      }

      // Non-element is used basically just as a hack around the widgets timing out
      const nonElements = [
        ...[...(document.querySelectorAll("[data-widget='m-wallet-identity-no-timeout']"))].map(e => { return { el: e, type: 'WalletNoTimeout' }; })
      ];

      if (nonElements.length) {
        timeout++;
      }

      if (elements.length) {
        // No need to keep checking for elements as we've found them all.
        clearInterval(mInterval as number);

        for (let i = 0; i < elements.length; i++) {
          const el = elements[i];

          // grab the DOM element's data- properties to use as propsData
          const data = (el as HTMLElement).dataset;

          // be sure to give proper fallback type
          const wallet = ('wallet' in data) ? data.wallet : undefined;
          let propsData = {};
          if (wallet) {
            propsData = {
              wallet: wallet
            };
          }

          renderComponentWithApp({
            el: el,
            // Entry point is MWalletIdentity
            component: (el as HTMLElement).id === 'm-wallet-identity-no-timeout' ? MWalletIdentityNoTimeout : MWalletIdentity,
            props: propsData
          });
        }
      }
    }, 1000);
  }
}

if (window) {
  useManifoldCSS({ reset: 'none', styles: 'vars' }, packageName);
  window.addEventListener('load', handleLoad);
  window.addEventListener(MANIFOLD_LOAD_REQUEST, handleLoad);
}
