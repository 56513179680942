import Badges from '../models/badges';

export interface IBadgesApiClient {
  getBadges(walletAddress: string): Promise<Badges>;
}

class BadgesAPIClient implements IBadgesApiClient {
  async getBadges (walletAddress: string): Promise<Badges> {
    const manifoldInfoPromise = fetch(`https://identity.api.manifoldxyz.dev/badges?walletAddress=${walletAddress}`);
    const foundationInfoPromise = fetch('https://hasura2.foundation.app/v1/graphql', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: '\n    query UserProfileByPublicKey($publicKey: String!) {\n  user: user_by_pk(publicKey: $publicKey) {\n    ownedArtworks: artworks(\n      limit: 12\n      where: {isIndexed: {_eq: true}, deletedAt: {_is_null: true}, ownerPublicKey: {_neq: $publicKey}}\n      distinct_on: ownerPublicKey\n    ) {\n      owner {\n        userIndex\n        publicKey\n        username\n        profileImageUrl\n        coverImageUrl\n        name\n        bio\n        moderationStatus\n        createdAt\n        isAdmin\n        links\n      }\n    }\n    collectorsCount: artworks_aggregate(\n      where: {isIndexed: {_eq: true}, deletedAt: {_is_null: true}, ownerPublicKey: {_neq: $publicKey}}\n      distinct_on: ownerPublicKey\n    ) {\n      aggregate {\n        count\n      }\n    }\n    ...UserProfileFragment\n  }\n}\n    \n    fragment UserProfileFragment on user {\n  ...UserFragment\n  twitSocialVerifs: socialVerifications(\n    where: {isValid: {_eq: true}, service: {_eq: "TWITTER"}}\n    limit: 1\n  ) {\n    ...SocialVerificationFragment\n  }\n  instaSocialVerifs: socialVerifications(\n    where: {isValid: {_eq: true}, service: {_eq: "INSTAGRAM"}}\n    limit: 1\n  ) {\n    ...SocialVerificationFragment\n  }\n  followerCount: follows_aggregate(where: {isFollowing: {_eq: true}}) {\n    aggregate {\n      count\n    }\n  }\n  followingCount: following_aggregate(where: {isFollowing: {_eq: true}}) {\n    aggregate {\n      count\n    }\n  }\n}\n    \n    fragment UserFragment on user {\n  userIndex\n  publicKey\n  username\n  profileImageUrl\n  coverImageUrl\n  name\n  bio\n  moderationStatus\n  createdAt\n  isAdmin\n  links\n}\n    \n\n    fragment SocialVerificationFragment on social_verification {\n  id\n  user\n  createdAt\n  updatedAt\n  expiresAt\n  lastCheckedAt\n  socialVerificationURL\n  verificationText\n  userId\n  username\n  isValid\n  service\n  failedReason\n  status\n}\n    ',
        variables: { publicKey: walletAddress }
      })
    });
    const results = await Promise.allSettled([manifoldInfoPromise, foundationInfoPromise]);

    const manifoldInfoResult = results[0].status === 'fulfilled' ? results[0].value : undefined;
    const foundationInfoResult = results[1].status === 'fulfilled' ? results[1].value : undefined;

    const manifoldInfo = manifoldInfoResult?.status === 200 ? await manifoldInfoResult.json() : undefined;
    const foundationInfo = foundationInfoResult?.status === 200 ? await foundationInfoResult.json() : undefined;

    return {
      opensea: manifoldInfo?.openseaUsername ? `https://opensea.io/${manifoldInfo?.openseaUsername}?tab=created` : `https://opensea.io/${walletAddress}?tab=created`,
      foundation: foundationInfo?.data?.user?.username ? `https://foundation.app/@${foundationInfo.data.user.username}` : undefined,
      superRare: manifoldInfo?.superrareUsername,
      niftyGateway: manifoldInfo?.niftyUsername ? `https://www.niftygateway.com/@${manifoldInfo.niftyUsername}` : undefined,
      twitter: manifoldInfo?.twitterUsername || undefined,
      ens: manifoldInfo?.userENS ? manifoldInfo.userENS : undefined,
      ensAvatar: manifoldInfo?.ensAvatar ? manifoldInfo.ensAvatar : undefined
    };
  }
}

export default new BadgesAPIClient();

/*
  openseaInfo example:
  {
    "username": "richerd",
    "account": {
      "user": {
        "username": "richerd"
      },
      "profile_img_url": "https://i.seadn.io/gae/5cjzazc_XNq9xj_0wf4_g38p6elFO-MjR8rntLSyLxMUD1-DPisUpFGV7HogvdWuePN6TcM1QbP5ARIJmQua63gTXIrf825R_pH3?w=500&auto=format",
      "address": "0xeb1c22baacafac7836f20f684c946228401ff01c",
      "config": "verified",
      "currencies": {}
    }
  }
*/
/*
  superRareInfo example:
  {
    "status": "SUCCESS",
    "result": {
      "userId": "255918",
      "username": "angrydee",
      "ethaddress": "05031766560bbccdc0aa48886996ba50147b459a",
      "ethereumAddress": "0x05031766560bbccdc0aa48886996ba50147b459a",
      "location": null,
      "bio": null,
      "avatar": null,
      "ethAddress": "05031766560bbccdc0aa48886996ba50147b459a",
      "fullName": null,
      "isVerified": null,
      "clubhouseLink": null,
      "discordLink": null,
      "facebookLink": null,
      "instagramLink": null,
      "redditLink": null,
      "snapchatLink": null,
      "soundcloudLink": null,
      "spotifyLink": null,
      "steemitLink": null,
      "tiktokLink": null,
      "twitterLink": null,
      "websiteLink": null,
      "youtubeLink": null,
      "noiseFilter": true,
      "mastheadUniversalTokenId": null,
      "following": {
        "totalCount": 0
      },
      "followers": {
        "totalCount": 0
      },
      "curatorSpaces": {
        "totalCount": 0,
        "nodes": []
      },
      "socials": {
        "clubhouse": null,
        "discord": null,
        "facebook": null,
        "instagram": null,
        "reddit": null,
        "snapchat": null,
        "soundcloud": null,
        "spotify": null,
        "steemit": null,
        "tiktok": null,
        "twitter": null,
        "website": null,
        "youtube": null
      },
      "communityCurator": {
        "inviter": [],
        "invitees": []
      },
      "spaces": null,
      "role": "COLLECTOR",
      "collectors": null
    }
  }
*/
/*
  foundationInfo example:
  {
    "data": {
      "user": {
        "ownedArtworks": [
          {
            "owner": {
              "userIndex": null,
              "publicKey": "0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799",
              "username": null,
              "profileImageUrl": null,
              "coverImageUrl": null,
              "name": null,
              "bio": null,
              "moderationStatus": "ACTIVE",
              "createdAt": "2022-07-22T06:10:41.148914",
              "isAdmin": false,
              "links": {}
            }
          },
          {
            "owner": {
              "userIndex": null,
              "publicKey": "0xBBD17b442f2250a2e53CAB0DAcCaB3dF144AA208",
              "username": null,
              "profileImageUrl": null,
              "coverImageUrl": null,
              "name": null,
              "bio": null,
              "moderationStatus": "ACTIVE",
              "createdAt": "2021-12-20T13:58:58.38711",
              "isAdmin": false,
              "links": {}
            }
          },
          {
            "owner": {
              "userIndex": null,
              "publicKey": "0xD4D2B38106E40f2693B617314Ba5f406985864eb",
              "username": null,
              "profileImageUrl": null,
              "coverImageUrl": null,
              "name": null,
              "bio": null,
              "moderationStatus": "ACTIVE",
              "createdAt": "2022-05-12T22:02:23.41192",
              "isAdmin": false,
              "links": {}
            }
          }
        ],
        "collectorsCount": {
          "aggregate": {
            "count": 3
          }
        },
        "userIndex": 224070,
        "publicKey": "0x05031766560bbCcDc0aa48886996bA50147b459A",
        "username": "angrydee",
        "profileImageUrl": "",
        "coverImageUrl": "",
        "name": "AngryDee",
        "bio": "",
        "moderationStatus": "ACTIVE",
        "createdAt": "2022-04-21T14:53:59.081064",
        "isAdmin": false,
        "links": {
          "tiktok": {
            "handle": "",
            "platform": "tiktok"
          },
          "twitch": {
            "handle": "",
            "platform": "twitch"
          },
          "discord": {
            "handle": "",
            "platform": "discord"
          },
          "twitter": {
            "handle": "",
            "platform": "twitter"
          },
          "website": {
            "handle": "",
            "platform": "website"
          },
          "youtube": {
            "handle": "",
            "platform": "youtube"
          },
          "facebook": {
            "handle": "",
            "platform": "facebook"
          },
          "snapchat": {
            "handle": "",
            "platform": "snapchat"
          },
          "instagram": {
            "handle": "",
            "platform": "instagram"
          }
        },
        "twitSocialVerifs": [
          {
            "id": "9d173e7d-8e15-4684-a30e-c15bcc743b45",
            "user": "0x05031766560bbCcDc0aa48886996bA50147b459A",
            "createdAt": "2022-04-21T14:55:41.997068",
            "updatedAt": "2022-04-21T14:55:42.952828",
            "expiresAt": null,
            "lastCheckedAt": "2022-04-21T14:55:42.948",
            "socialVerificationURL": "https://twitter.com/angrydudez/status/1517155042645532675",
            "verificationText": "I’m on @foundation 🌐\n\n0x05031766560bbCcDc0aa48886996bA50147b459A\n\nhttps://t.co/JIijPcSYYU",
            "userId": "1376622913202323465",
            "username": "angrydudez",
            "isValid": true,
            "service": "TWITTER",
            "failedReason": null,
            "status": null
          }
        ],
        "instaSocialVerifs": [],
        "followerCount": {
          "aggregate": {
            "count": 1
          }
        },
        "followingCount": {
          "aggregate": {
            "count": 0
          }
        }
      }
    }
  }
*/
