// custom manifold event names -- augments the global document interface

// listens to
/** re-runs the injection logic for widgets */
export const MANIFOLD_LOAD_REQUEST = 'm-refresh-widgets';
interface ManifoldRefreshDetail {
  widget: string;
}
export type MManifoldRefreshWidgets = CustomEvent<ManifoldRefreshDetail>

// declaration merging with global window event map
// https://www.typescriptlang.org/docs/handbook/declaration-merging.html#global-augmentation and https://www.typescriptlang.org/docs/handbook/declaration-merging.html#merging-interfaces
declare global {
  interface WindowEventMap {
    [MANIFOLD_LOAD_REQUEST]: MManifoldRefreshWidgets,
  }
}
